$black0: rgba(0, 0, 0, 0);
$black83: rgba(0, 0, 0, 0.83);
$black9: rgba(0, 0, 0, 0.9);
$black7: rgba(0, 0, 0, 0.7);
$black-disabled: rgba(3, 8, 44, 0.7);

$bright-cyan: #47c8ff;
$bright-cyan2: rgba(71, 200, 255, 0.2);
$white: #ebebeb;
$black: #000000;
$link: #3ec7ff;
$white: rgba(255, 255, 255, 1);
$white-tr: rgba(235, 235, 235, 0.5);
$white3: rgba(235, 235, 235, 0.3);
$white8: rgba(235, 235, 235, 0.8);
$white13: rgba(255, 255, 255, 0.13);

$color-red: #d91f00;
$color-secondary-dark-1: #091629;
$color-secondary-dark-6: #141b21;
$color-secondary-white: #ffffff;
$color-primary-surface: #1b2329;
$color-primary-white: #ffffff;

$color-primary-brand: #FBF433;
$color-primary-surface: #12253f;

$color-opacity-92: rgba(255, 255, 255, 0.92); // opacity-a
$color-opacity-70: rgba(255, 255, 255, 0.7); // opacity-b
$color-opacity-30: rgba(255, 255, 255, 0.3); // opacity-c
$color-opacity-12: rgba(255, 255, 255, 0.12); // opacity-e
$color-background-loading: rgba(255, 255, 255, 0.1);

$color-bg-overlay: rgba(4, 10, 17, 0.70);

$color-secondary-dark-grey: #141b21;
