@use 'styles' as *;

.form-button {
    width: 100%;
    max-width: 374px;
    border: none;
    height: 44px;
    color: #000000;
    background: $color-primary-brand;
    margin: 37px auto 0;
    font-weight: 400;
    font-size: 18px;
    line-height: 110%;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 12px 24px;
    border-radius: $radius;

    @media screen and (min-width: 768px) {
        height: 44px;
    }
}

.form-button:disabled {
    opacity: 0.2;
}

.ghost {
    color: $color-opacity-92;
    background: rgba(255, 255, 255, 0.05);
    border: rgba(255, 255, 255, 0.2) 1px solid;

    &:hover {
        color: $color-primary-white;
        background: $color-opacity-12;
        border: $color-opacity-70 1px solid;
    }

    &:active {
        color: $color-primary-brand;
        background: $color-background-loading;
        border: 1px solid $color-primary-brand;
    }
}
