@use 'styles' as *;

.changePasswordScreen {
    .changePasswordHeader {
        display: block;
        position: relative;

        .heading {
            font-size: 26px;
            margin: 0;
            font-weight: 400;
            font-style: normal;
            font-stretch: normal;
            line-height: 0.92;
            letter-spacing: normal;
            text-align: center;
        }
    }

    .backIcon {
        position: absolute;
        background: transparent;
        border: none;
        background-repeat: no-repeat;
        font-size: 0;
        background-image: url('../../styles/assets/back-arrow.png');
        background-size: 10px 20px !important;
        width: 10px !important;
        height: 20px;
        top: 0;
        left: 8px;
        cursor: pointer;
    }

    .formContainer {
        display: block;

        .formContent {
            margin-top: 95px;

            .inputField {
                margin-bottom: 40px;
            }
        }

        button {
            width: 100%;
        }

        @media screen and (max-width: 1024px) {
            display: flex;
            align-items: center;
            height: calc(100vh - 190px);

            .formContent {
                margin-top: 0;
            }
        }

        @media screen and (max-width: 499px) {
            display: block;

            .formContent {
                margin-top: 54px;
            }
        }
    }
}

.confirmationScreen {
    padding: 48px 32px 58px;
    width: 392px;

    .header {
        font-size: 24px;
        line-height: 30px;
        margin: 10px 0;
        color: $color-primary-white;
        opacity: 0.92;
    }

    .note {
        font-size: 16px;
        line-height: 21px;
        letter-spacing: -0.31px;
        color: $color-primary-white;
        opacity: 0.7;
    }

    .confirmationText {
        & * {
            text-align: center;
        }
    }

    .checkIcon {
        background-image: url('../../styles/assets/result.svg');
        background-repeat: no-repeat;
        background-size: 100px;
        background-position-x: center;
        width: 100%;
        height: 64px;
    }

    .buttons {
        button {
            font-family: $font-family-medium;
            &:first-child {
                margin-top: 37px;
            }
            margin: 20px auto 0;
        }
    }
}
