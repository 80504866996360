@use 'styles' as *;

.pinScreen {
    height: 100%;
    width: 100vw;
    background: transparent;
    margin-top: -4rem;

    @media screen and (max-width: 798px) {
        margin-top: 1rem;
    }

    .pinOverlay {
        position: absolute;
        width: 100vw;
        opacity: 0.65;
        background-image: linear-gradient(246deg, $black0, $black9);

        @media screen and (max-width: 650px) {
            background-image: none;
        }
    }

    .container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-end;
        width: 100vw;
        bottom: 0;
        padding: 0 10px;


        &>* {
            text-align: center;
        }

        .welcome {
            font-weight: 400;
            font-size: 20px;
            line-height: 25px;
            letter-spacing: -0.45px;
            color: rgba(255, 255, 255, 0.92);

            position: relative;
            left: 0;
            right: 0;
            text-align: center;
            top: 0;
            color: $white;
            z-index: 10;
            padding: 12px 40px 12px 20px;
            background: #141B21;
            box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
            border-radius: 100px;


            &>div {
                margin: 0 5px;
                display: flex;
                align-items: center;

                &>img {
                    margin-right: 1rem;
                }
            }

            @media screen and (max-width: 375px) {
                font-size: 1rem;
            }

            @media screen and (min-width: 375px) {
                font-size: 18px;
            }

            @media screen and (min-width: 575px) {
                font-size: 20px;
            }
        }

        .pinGradient {
            display: none;

            @media screen and (max-width: 650px) {
                position: fixed;
                display: block;
                top: 0;
                width: 100%;
                height: calc(100vw * 1.1);
                object-fit: contain;
                z-index: 10;
            }
        }

        .container-inputs {
            z-index: 20;
            margin-bottom: 38px;
            margin-top: 12%;
            background: $color-primary-surface;
            border: 1px solid $color-opacity-12;
            box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.14), 0px 3px 3px rgba(0, 0, 0, 0.12), 0px 1px 8px rgba(0, 0, 0, 0.2);
            border-radius: 8px;
        }

        .label {
            color: $color-opacity-92;
            line-height: 2rem;
            letter-spacing: 0.18px;

            &.title {
                font-family: $font-family-regular;
                font-size: 24px;
                margin-top: 39px;
                margin-bottom: 27px;

                @media screen and (max-width: 375px) {
                    font-size: 1rem;
                }

                @media screen and (min-width: 375px) {
                    font-size: 18px;
                }

                @media screen and (min-width: 460px) {
                    font-size: 20px;
                }

                @media screen and (min-width: 500px) {
                    font-size: 22px;
                }

                @media screen and (min-width: 575px) {
                    font-size: 24px;
                }
            }

            &.contact {
                margin-top: 40px;
                font-weight: 400;
                font-size: 14px;
                line-height: 16px;
                color: $color-opacity-70;
            }

            a {
                color: $color-primary-brand;
            }
        }

        .pinContainer {
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 4px 8px;
        }

        .pinField {
            position: relative;
            width: 90px;
            height: 90px;
            margin-bottom: 30px;
            background: $color-opacity-12;
            border: 2px solid $color-opacity-12;
            border-radius: 4px;

            &:not(:first-child) {
                margin-left: 10px;
            }

            @media screen and (max-width: 375px) {
                width: 36px;
                height: 36px;
            }

            @media screen and (min-width: 375px) {
                width: 40px;
                height: 40px;
            }

            @media screen and (min-width: 460px) {
                width: 52px;
                height: 52px;
            }

            @media screen and (min-width: 575px) {
                width: 64px;
                height: 64px;
            }

            @media screen and (min-width: 650px) {
                width: 72px;
                height: 72px;
            }

            @media screen and (min-width: 1024px) {
                width: 90px;
                height: 90px;
            }

            input {
                position: absolute;
                text-align: center;
                width: 100%;
                height: 100%;
                left: 0;
                padding: 0;
                outline: none;
                font-size: 60px;
                background-color: $white13;
                z-index: 1;
                border: 1.6px solid transparent;
                color: transparent;
                text-shadow: 0 0 0 $white;
                outline: none;
                letter-spacing: -0.5px;

                &:required {
                    box-shadow: none;
                }

                &:invalid {
                    box-shadow: 0 0 3px transparent;
                }

                &:focus {
                    outline: none;
                    border: 2px solid $white;
                    border-radius: 4px;
                    background-color: transparent;
                }

                @media screen and (max-width: 375px) {
                    font-size: 24px;
                }

                @media screen and (min-width: 375px) {
                    font-size: 26px;
                }

                @media screen and (min-width: 460px) {
                    font-size: 35px;
                }

                @media screen and (min-width: 575px) {
                    font-size: 42px;

                    &:focus {
                        border: 1.1px solid $white;
                    }
                }

                @media screen and (min-width: 650px) {
                    font-size: 3rem;

                    &:focus {
                        border: 1.3px solid $white;
                    }
                }

                @media screen and (min-width: 1024px) {
                    font-size: 60px;

                    &:focus {
                        border: 1.4px solid $white;
                    }
                }

            }
        }

        button {
            width: 60%;
            max-width: 374px;
            margin: auto;
            margin-bottom: 2rem;
            margin-top: 60px;
            height: 44px;
            font-size: 20px;
            line-height: 1.32;
            letter-spacing: 1px;
            border: none;
            color: $black;
            background: $color-primary-brand;
            box-shadow: 0px 12px 17px rgba(0, 0, 0, 0.14), 0px 5px 22px rgba(0, 0, 0, 0.12), 0px 7px 8px rgba(0, 0, 0, 0.2);
            border-radius: 4px;
            outline: none !important;
            cursor: pointer;

            &:disabled {
                background: $color-primary-brand;
                opacity: 0.3;
                border-radius: 4px;
                color: #000000;

                @media screen and (max-width: 650px) {
                    color: $black-disabled;
                    background-color: $bright-cyan2;
                }
            }
        }

        .inputContainer {
            display: flex;
            justify-content: center;
            margin: 0 40px;
        }

        input[type='number']::-webkit-inner-spin-button,
        input[type='number']::-webkit-outer-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
    }

    .error {
        margin: 0px 0 10px;
        height: auto;
    }
}
