@use 'styles/colors' as *;

.formError {
  border: 1px solid  $color-red;
  border-radius: 4px;
  width: 100%;
  color: $color-secondary-white;
  font-weight: 500;
  font-size: 12px;
  margin: 8px 0;
  line-height: 16px;
  padding: 8px;
  display: flex;
  align-items: center;
  min-height: 30px;
  & > svg {
    margin-right: 8px;
  }
}
