@use 'styles/colors.scss' as *;
@use 'styles/typography.scss' as *;

.result-screen {
    width: 40%;
    height: 40%;
    margin: auto;
    margin-top: 10%;
    color: #ebebeb;
    max-width: 400px;

    display: flex;
    justify-content: center;
    align-items: center;

    .container {
        background: $color-primary-surface;
        padding: 48px 32px 58px;
        border: 1px solid $color-opacity-12;
        box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.14), 0px 3px 3px rgba(0, 0, 0, 0.12), 0px 1px 8px rgba(0, 0, 0, 0.2);
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        justify-content: center;

        @media screen and (max-width: 600px) {
            margin: 0 30px;
        }


        .title {
            font-weight: 400;
            font-size: 24px;
            line-height: 30px;
            text-align: center;
            letter-spacing: 0.07px;
            color: $color-opacity-92;
            margin-bottom: 10px;

            @media screen and (max-width: 800px) {
                font-size: 20px;
                line-height: 1;
            }

            @media screen and (max-width: 449px) {
                font-size: 1rem;

            }
        }

        .image-container {
            display: flex;
            justify-content: center;
            margin-bottom: 0;
        }

        .note {
            font-weight: 400;
            font-size: 16px;
            line-height: 21px;
            text-align: center;
            letter-spacing: -0.31px;
            color: $color-opacity-70;
            margin-bottom: 44px;


            @media screen and (max-width: 800px) {
                font-size: 14px;
            }

            @media screen and (max-width: 449px) {
                font-size: 12px;

            }
        }

        .login-button {
            width: 100%;
            display: flex;
            justify-content: center;
        }

        button {
            font-size: 1rem;
            line-height: 21px;
            text-align: center;
            letter-spacing: -0.31px;
            border: none;
            color: #ebebeb;
            background: $color-primary-brand;
            box-shadow: 0px 24px 38px rgba(0, 0, 0, 0.14), 0px 9px 46px rgba(0, 0, 0, 0.12), 0px 11px 15px rgba(0, 0, 0, 0.2);
            border-radius: 4px;
            padding: 12px 24px;
            max-width: 328px;
            width: 100%;

            &.loginButton {
                font-family: $font-family-medium;
                color: $color-primary-surface;
                &:hover {
                    cursor: pointer;
                }
            }

            @media screen and (max-width: 600px) {
                width: 100%;
                margin-bottom: 20px;
            }
        }
    }
}