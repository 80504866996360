@use 'styles/colors.scss' as *;

.forgot-password-screen {
    position: relative;
    background: #1B2329;
    border: 1px solid $color-opacity-12;
    border-radius: 8px;
    .error {
        color: $color-red;
        text-align: center;
    }
    .forgot-password-header {
        display: flex;
        position: relative;

        .heading {
            font-size: 1rem;
            margin: 0;
            font-weight: 400;
            line-height: 21px;
            letter-spacing: -0.31px;
            text-align: center;
            color: $color-opacity-92;
            margin-left: auto;
        }
    }

    .back-icon {
        position: absolute;
        background: transparent;
        border: none;
        background-repeat: no-repeat;
        font-size: 0;
        background-image: url('../../styles/assets/back-icon.svg');
        width: 16px !important;
        height: 12px;
        top: 5;
        left: 8px;
        cursor: pointer;
    }

    .form-content {
        margin-top: 95px;

        .social-login-info {
            font-size: 18px;
            margin-bottom: 40px;

            &>div:first-child {
                margin-bottom: 10px;
            }
        }

        .input-field {
            margin-bottom: 0;
        }
    }

    .send-screen {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        background: linear-gradient(156.3deg, #50626D 0%, #141B21 84.75%);
        z-index: 10;

        .send-container {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding: 3rem 2rem 58px;
            background: #1B2329;
            border: 1px solid $color-opacity-12;
            box-sizing: border-box;
            box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.14), 0px 3px 3px rgba(0, 0, 0, 0.12), 0px 1px 8px rgba(0, 0, 0, 0.2);
            border-radius: 8px;
            max-width: 392px;
            min-height: 150px;

            @media screen and (max-width: 397px) {
                min-height: auto;
                margin-inline: 2rem;
            }

            .header {
                font-size: 1.5rem;
                margin: 10px 0;
                color: $color-primary-white;
                margin-bottom: 10px;
            }

            .send-text {
                font-weight: 400;
                font-size: 1rem;
                line-height: 21px;
                text-align: center;
                letter-spacing: -0.31px;
                color: $color-opacity-70;
            }
        }

        .send-image {
            background-image: url('../../styles/assets/email-icon.svg');
            background-repeat: no-repeat;
            background-size: 60px 60px;
            background-position-x: center;
            width: 100%;
            height: 65px;
        }

        .send-text {
            font-size: 16px;
            text-align: center;
        }

        .icon {
            position: absolute;
            background: transparent;
            border: none;
            background-repeat: no-repeat;
            font-size: 0;
            cursor: pointer;

            background-image: url('../../styles/assets/close_icon.png');
            width: 18px !important;
            height: 18px;
            min-width: 18px;
            top: 16px;
            right: 16px;
            background-position: 0 100%;
        }
    }
}
.passwordSentWrapper {
    max-width: 360px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    margin-bottom: 2.5rem;
    font-size: 18px;
    .passwordSent {
        font-size: 38px;
        line-height: 40px;
        text-align: center;
        color: $color-opacity-92;
        margin: 30px 0;
        @media screen and (max-width: 1000px) {
            font-size: 28px;
            line-height: 30px;
        }
    }

    .passwordSentText {
        font-size: 18px;
        line-height: 21px;
        text-align: center;
        color: $color-opacity-70;
        padding: 5px 0;
        margin-bottom: 18px;
        @media screen and (max-width: 1000px) {
            font-size: 14px;
            line-height: 17px;
        }
    }

    .button {
        min-width: 132px;
        margin: 5px 0;
    }
    .passwordSentClose {
        padding: 12px 54px;
        gap: 8px;
        width: 132px;
        height: 45px;
        background: rgba(255, 255, 255, 0.05);
        border: 1px solid $color-opacity-30;
        border-radius: 4px;

        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 21px;
        color: $color-opacity-92;
        cursor: pointer;
        text-transform: uppercase;
        &:hover {
            color: #fff;
            background: hsla(0,0%,100%,.12);
            border: 1px solid hsla(0,0%,100%,.7);
        }
    }
}
