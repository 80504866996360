@use 'styles/colors.scss' as *;

.container {
    background: $color-primary-surface;
    border: 1px solid rgba(255, 255, 255, 0.12);
    border-radius: 8px;
    box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.14), 0px 3px 3px rgba(0, 0, 0, 0.12), 0px 1px 8px rgba(0, 0, 0, 0.2);

    margin: auto;
    margin-bottom: 4rem;
    padding: 2rem 31px;

    @media screen and (max-width: 543px) {
        width: auto;
    }
}