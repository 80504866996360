@use 'styles' as *;

.icon {
    position: absolute;
    background: transparent;
    border: none;
    background-repeat: no-repeat;
    font-size: 0;
    cursor: pointer;

    &:focus,
    &:focus-visible {
        border: 1px solid $color-primary-brand;
    }

    &.tooltip {
        background-image: url('../../styles/assets/info_icon.svg');
        background-size: 22px 22px !important;
        width: 22px !important;
        height: 22px;
        top: 0;
        right: 0;
    }

    &.close {
        background-size: 24px 24px !important;
        background-image: url('../../styles/assets/close_icon.png');
        width: 18px !important;
        height: 18px;
        top: 16px;
        right: 16px;
        background-position: center;
    }

    // opacity: 0.2;
}

.tooltip-content {
    font-family: $font-family-regular;
    display: block;
    display: none;
    position: absolute;
    background: $color-secondary-dark-1;
    z-index: 10;
    padding: 14px 16px 20px;
    top: 0;
    right: 0;
    max-width: 415px;
    width: auto;
    box-sizing: border-box;

    @media screen and (max-width: 499px) {
        position: fixed;
        width: auto;
        padding: 0;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 100;
        box-sizing: border-box;
        padding: 60px 16px 0;
        max-width: unset;
    }

    &.hidden {
        display: none;
    }

    &.visible {
        display: block;
    }

    .header {
        font-size: 22px;
        margin: 0 0 11px;

        @media screen and (max-width: 499px) {
            font-size: 28px;
            margin: 0 0 18px;
        }
    }

    .content {
        opacity: 0.6;
        font-size: 14px;
        font-weight: 400;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.21;
        letter-spacing: normal;
        color: #fff;
    }
}
