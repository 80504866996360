@use 'index.scss' as *;

body {
    margin: 0;
    font-family: $font-family-medium, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.app {
    * {
        font-family: $font-family-regular;
        font-size: 20px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        letter-spacing: normal;

        @media screen and (max-width: 499px) {
            font-size: 16px;
        }
    }
}

a {
    line-height: 1.22;
    letter-spacing: 0.35px;
}

.error {
    display: block;
    border: none;
    width: 100%;
    padding: 0;
    margin: 20px 0 0;
    text-align: center;
    font-size: 16px;
    height: 16px;
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: 0.16px;
    color: #ff4040;
}
